"use client";

import { Dialog } from "@headlessui/react";
import {
  Bars3Icon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { PhoneIcon } from "@heroicons/react/24/solid";
import { DropdownNavItem } from "./dropdown-nav-item";
import IconButton from "../web-components/icon-button";

export default function Navbar() {
  const navigation = [
    {
      title: "Services",
      href: "/services",
      children: [
        { title: "Design & Build", href: "/services/design-build" },
        { title: "Gardening", href: "/services/gardening" },
        { title: "Lawn Maintenance", href: "/services/lawn-maintenance" },
        { title: "Irrigation", href: "/services/irrigation" },
        { title: "Lighting", href: "/services/lighting" },
      ],
    },
    { title: "Contact", href: "/contact" },
  ];
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="bg-main-grey-800 sticky inset-x-0 top-0 z-50 w-full mx-auto">
      <a
        type="button"
        href={"/list-a-site"}
        className="inline-flex lg:hidden z-10 bg-main items-center font-semibold justify-center p-2.5 text-main-grey w-full mx-auto"
      >
        <span className={""}>Get Your Site Listed</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </a>
      <nav
        className="flex items-end justify-center lg:justify-between pb-2 pt-2 lg:px-8 drop-shadow-md"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <div className={"flex flex-col"}>
            <a
              href="/"
              className="p-1.5 inline-flex items-center font-serif font-semibold text-2xl text-main-grey"
            >
              Bitcoin Mining Sites For Sale
            </a>
          </div>
        </div>
        {/*<div className="flex lg:hidden">*/}
        {/*  <button*/}
        {/*    type="button"*/}
        {/*    className="inline-flex items-center justify-center rounded-md p-2.5 text-main-grey"*/}
        {/*    onClick={() => setMobileMenuOpen(true)}*/}
        {/*  >*/}
        {/*    <span className="sr-only">Open main menu</span>*/}
        {/*    <Bars3Icon className="h-8 w-8" aria-hidden="true" />*/}
        {/*  </button>*/}
        {/*</div>*/}
        <div className="hidden lg:flex items-center font-sans tracking-wide">
          <a
            type="button"
            href={`/list-a-site`}
            className="inline-flex hidden order-1 lg:flex items-center font-semibold justify-center rounded-md p-2.5 text-main-grey border-2 border-main-grey lg:ml-4 lg:self-center lg:mt-2 hover:bg-main"
          >
            <span className={""}>Get Your Site Listed</span>
            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
          </a>
          {/*  {navigation.map((navItem) => {*/}
          {/*    return navItem.children ? (*/}
          {/*      <DropdownNavItem*/}
          {/*        key={navItem.title}*/}
          {/*        name={navItem.title}*/}
          {/*        href={navItem.href}*/}
          {/*        items={navItem.children.map((child) => ({*/}
          {/*          name: child.title,*/}
          {/*          href: child.href,*/}
          {/*        }))}*/}
          {/*      />*/}
          {/*    ) : (*/}
          {/*      <div*/}
          {/*        key={navItem.title}*/}
          {/*        className={*/}
          {/*          "text-md relative transition border-opacity-0 px-6 font-semibold leading-6 text-main-grey border-b-4 border-main drop-shadow-none hover:border-b-4 hover:border-main-grey pb-1.5"*/}
          {/*        }*/}
          {/*      >*/}
          {/*        <a href={navItem.href} key={navItem.href} className={"pt-4"}>*/}
          {/*          {navItem.title}*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*    );*/}
          {/*  })}*/}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50 bg-main" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <a href="/" className="p-2 font-serif font-semibold font-2xl">
              Bitcoin Mining Sites For Sale
            </a>
            <button
              type="button"
              className="rounded-md p-2.5 text-main-grey"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.href}
                    href={item.href}
                    className="text-white block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-main-600"
                  >
                    {item.title}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
