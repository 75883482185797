import React, { useRef, useState } from "react";
import useOnScreen from "../hooks/useOnScreen";

interface AnimatedElementProps {
  animationClass: string;
  children: React.ReactNode;
}

export default function AnimatedElement({
  animationClass,
  children,
}: AnimatedElementProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const onScreenVisible = useOnScreen(ref);

  if (!visible && onScreenVisible) {
    setVisible(true);
  }

  return (
    <div
      ref={ref}
      className={visible ? `${animationClass} visible delay-150` : "invisible"}
    >
      {children}
    </div>
  );
}
