import React from "react";

interface PanelProps {
  backgroundClass?: string | null;
  classNames?: string | null;
  children: React.ReactNode;
}

export default function Panel({
  backgroundClass,
  classNames,
  children,
}: PanelProps) {
  return (
    <div className={`py-8 lg:py-16 px-6 lg:px-8 ${backgroundClass}`}>
      <div className={`max-w-screen-2xl lg:mx-auto ${classNames}`}>
        {children}
      </div>
    </div>
  );
}
