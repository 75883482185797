import React from "react";
import Panel from "./panel";
import AnimatedElement from "./animated-element";

type SubtitleTextCardProps = {
  title: string;
  children: React.ReactNode;
  backgroundColor?: string | null;
  subtitle?: string | null;
  titleClass?: string | null;
};

export default function SubtitleTextCard({
  title,
  children,
  backgroundColor,
  subtitle = null,
  titleClass = null,
}: SubtitleTextCardProps) {
  return (
    <Panel backgroundClass={`${backgroundColor || "bg-inherit"}`}>
      <div className="flex flex-col items-start lg:items-center justify-center space-y-3 lg:space-y-6">
        <AnimatedElement animationClass={"animate-fade-in lg:animate-slide-in"}>
          <div
            className={`${titleClass ? titleClass : "text-2xl font-semibold lg:text-4xl"} font-serif text-slate-800 text-left lg:text-center`}
          >
            {title}
          </div>
          {subtitle && (
            <div
              className={`text-lg lg:text-xl font-serif text-main-700 text-left lg:text-center pt-4`}
            >
              {subtitle}
            </div>
          )}
        </AnimatedElement>
        <AnimatedElement animationClass={"animate-fade-in lg:animate-slide-in"}>
          <div className="text-base leading-loose max-w-screen-lg text-left lg:text-justify">
            {children}
          </div>
        </AnimatedElement>
      </div>
    </Panel>
  );
}
