import PropTypes from "prop-types";
import React, { useState } from "react";
import Layout from "../web-components/layout";
import HeroSection from "../web-components/hero-section";
import SubtitleTextCard from "../web-components/subtitle-text-card";
import BrowseSites from "./browse-sites";
import CtaBanner from "../web-components/cta-banner";
import SubscriberModal from "./subscriber-modal";
import Faq from "./faq";

const Home = ({ sites }) => {
  const [showModal, setShowModal] = useState(false);

  const handleCtaClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Layout>
      <SubtitleTextCard
        title={"Site Development Opportunities for Bitcoin Mining"}
      >
        Looking for a new site to set up your Bitcoin Mining operations? Browse
        our curated selection of bitcoin mining sites below. All sites have had
        extensive due diligence performed by the CryptoCaddy team. Use the
        button below each listing to request more information about any of the
        sites.
        <br />
        <br />
        Questions or feedback about the directory? Contact us any time at{" "}
        <a
          href={"mailto:sites@cryptocaddy.us"}
          className={"text-main font-semibold underline"}
        >
          sites@cryptocaddy.us.{" "}
        </a>
      </SubtitleTextCard>
      <BrowseSites sites={sites} />
      {/*<HeroSection*/}
      {/*  pathToImage={"/images/substation.jpg"}*/}
      {/*  title={"Find Bitcoin Mining Sites For Sale"}*/}
      {/*  ctaText={"Browse Sites"}*/}
      {/*  ctaHref={"/browse"}*/}
      {/*/>*/}
      {/*<SubtitleTextCard*/}
      {/*  title={"Ditch Shady Telegram Conversations"}*/}
      {/*  subtitle={"Curated, DD-Complete Mining Sites Starting at 2MW"}*/}
      {/*></SubtitleTextCard>*/}
      {/*<Faq />*/}
      <CtaBanner
        text={"🔔 Get alerted when new sites get added"}
        ctaText={"Sign up for alerts"}
        onClick={handleCtaClick}
      />
      <SubscriberModal onClose={handleCloseModal} isOpen={showModal} />
    </Layout>
  );
};

Home.propTypes = {
  name: PropTypes.string.isRequired, // this is passed from the Rails view
};

export default Home;
