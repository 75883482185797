import React, { Fragment, useState } from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { classNames } from "../utility/class-utility";
import Layout from "../web-components/layout";
import Breadcrumbs from "./breadcrumbs";
import RateBadge from "./rate-badge";
import SiteDescription from "./site-description";
import Badge from "./badge";
import RequestInfoModal from "./request-info-modal";

const faqs = [
  {
    question: "What are the next steps after I request more information?",
    answer:
      "You will be contacted by a member of the CryptoCaddy team to sign an NDA. From there, you will get access to the full data room for the site.",
  },
  {
    question: "What due diligence has been performed?",
    answer:
      "CryptoCaddy has a rigorous due diligence process that we perform for each site listed. This includes verifying lease information, utility/PPA agreements, rate calculations, permit status, and much more. As always, it is always recommended to still perform your own due diligence before finalizing any agreements.",
  },
  {
    question: "What O&M services are available for these sites?",
    answer:
      "CryptoCaddy is an industry leading hosting company that now offers O&M services to select clients. Our O&M services include everything from on-the-ground technicians, to monthly billing for your hosting clients. Visit our <a target='_blank' class='underline font-semibold text-main' href='/operations-and-management'>Operations and Management</a> page to learn more.",
  },
];

const DescriptionItem = ({ label, value }) => {
  return (
    <div>
      <dt className="text-sm font-medium text-gray-900">{label}</dt>
      <dd className="mt-1 text-sm text-slate-700 sm:mt-2">{value}</dd>
    </div>
  );
};

export default function SiteShow({ site }) {
  console.log(site);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Layout>
      <Breadcrumbs
        items={[{ href: "/", name: "Sites" }]}
        current={{ href: "#", name: `${site.name} (${site.locationState})` }}
      />
      <div className="mx-auto px-4 pt-4 sm:px-6 sm:pt-6 lg:max-w-7xl lg:px-8 pb-24">
        {/* Product */}
        <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
          {/* Product image */}
          <div className="lg:col-span-4 lg:row-end-1">
            <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
              <img src={site.imageSrc} className="object-cover object-center" />
            </div>
          </div>

          {/* Product details */}
          <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
            <div className="flex flex-col-reverse">
              <div className="mt-4">
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  {site.name} ({site.locationState})
                </h1>

                <h2 id="information-heading" className="sr-only">
                  Product information
                </h2>
                <p className="mt-2 text-base tracking-tight text-slate-500">
                  {site.powerAvailableNowMw} MW Available (scale to{" "}
                  {site.powerAvailableTotalMw} MW total)
                </p>
              </div>

              <div>
                <RateBadge site={site} />
              </div>
            </div>

            <div className="mt-6">
              <SiteDescription description={site.description} />
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
              <button
                type="button"
                className="flex w-full items-center justify-center rounded-md border border-transparent bg-main px-8 py-3 text-base font-medium text-white hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-main focus:ring-offset-2 focus:ring-offset-gray-50"
                onClick={() => setIsModalOpen(true)}
              >
                Get More Information
              </button>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Tags</h3>
              <div className="flex flex-row gap-2 mt-4 text-gray-500">
                {site.tags.map((tag) => (
                  <Badge color={tag.color} text={tag.name} />
                ))}
              </div>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">
                Fill Me In Dammit
              </h3>
              <p className="mt-4 text-sm text-gray-500">
                Blah de blah.{" "}
                <a
                  href={"/"}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Read full license
                </a>
              </p>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h3 className="text-sm font-medium text-gray-900">Share</h3>
              <ul role="list" className="mt-4 flex items-center space-x-6">
                <li>
                  <a
                    href={`https://www.facebook.com/sharer.php?u=https%3A%2F%2F${document.location.host}${document.location.pathname}%2F`}
                    className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                    target="_blank"
                  >
                    <span className="sr-only">Share on Facebook</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5"
                    >
                      <path
                        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      />
                    </svg>
                  </a>
                </li>
                <li>
                  <a
                    href={`https://twitter.com/intent/tweet?url=https%3A%2F%2F${document.location.host}${document.location.pathname}%2F&text=Check%20out%20this%20site!`}
                    className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                    target="_blank"
                  >
                    <span className="sr-only">Share on X</span>
                    <svg
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      className="h-5 w-5"
                    >
                      <path d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
            <TabGroup>
              <div className="border-b border-gray-200">
                <TabList className="-mb-px flex space-x-8">
                  <Tab className="whitespace-nowrap border-b-2 border-transparent py-6 text-sm font-medium text-gray-700 hover:border-gray-300 hover:text-gray-800 data-[selected]:border-main data-[selected]:text-main">
                    Site Details
                  </Tab>
                  <Tab className="whitespace-nowrap border-b-2 border-transparent py-6 text-sm font-medium text-gray-700 hover:border-gray-300 hover:text-gray-800 data-[selected]:border-main data-[selected]:text-main">
                    FAQ
                  </Tab>
                </TabList>
              </div>
              <TabPanels as={Fragment}>
                <TabPanel className="-mb-10">
                  <h3 className="sr-only">Site Details</h3>
                  <div className="flex space-x-4 text-sm text-gray-500">
                    <div
                      // LOL
                      className={classNames(
                        0 === 0 ? "" : "border-t border-gray-200",
                        "py-10"
                      )}
                    >
                      <h3 className="font-medium text-lg text-gray-900">
                        Site {site.name}
                      </h3>
                      <p>
                        Added on{" "}
                        <time dateTime={site.addedAt}>{site.addedAt}</time>
                      </p>

                      <dl className="grid py-6 grid-cols-1 lg:grid-cols-2 gap-x-40 gap-y-4">
                        <DescriptionItem
                          label="Location"
                          value={`${site.locationState}, USA`}
                        />
                        <DescriptionItem
                          label="All-In Rate"
                          value={
                            site.allInPowerCostMwh ? (
                              <span>${site.allInPowerCostDisplay} / kWh</span>
                            ) : (
                              <span>
                                ${site.allInPowerCostLowDisplay} - $
                                {site.allInPowerCostHighDisplay} / kWh
                              </span>
                            )
                          }
                        />
                        <DescriptionItem
                          label="Uptime"
                          value={
                            site.uptime ? (
                              <span>{site.uptime}%</span>
                            ) : (
                              <span>
                                {site.uptimeLow}% - {site.uptimeHigh}%
                              </span>
                            )
                          }
                        />
                        <DescriptionItem
                          label="Power Available Now"
                          value={`${site.powerAvailableNowMw} MW`}
                        />
                        <DescriptionItem
                          label="Total Power Available"
                          value={`${site.powerAvailableTotalMw} MW`}
                        />
                        <DescriptionItem
                          label="Land Available"
                          value={`${site.landAvailableAcr} acres`}
                        />
                        <DescriptionItem
                          label="Land Status"
                          value={site.landStatus}
                        />
                      </dl>
                    </div>
                  </div>
                </TabPanel>

                <TabPanel className="text-sm text-gray-500">
                  <h3 className="sr-only">Frequently Asked Questions</h3>

                  <dl>
                    {faqs.map((faq) => (
                      <Fragment key={faq.question}>
                        <dt className="mt-10 font-medium text-gray-900">
                          {faq.question}
                        </dt>
                        <dd className="prose prose-sm mt-2 max-w-none text-gray-500">
                          <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        </dd>
                      </Fragment>
                    ))}
                  </dl>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        </div>
      </div>
      <RequestInfoModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        siteName={site.name}
        siteId={site.id}
      />
    </Layout>
  );
}
