import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";

export default function Layout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <main className={"bg-white max-w-screen overflow-hidden mx-auto"}>
      <Navbar />
      <div className="min-h-[800px]">{children}</div>
      <Footer />
    </main>
  );
}
