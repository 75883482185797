import React from "react";
import SiteGrid from "./site-grid";
import Panel from "../web-components/panel";

type FuelSource =
  | "Wind"
  | "Natural Gas"
  | "Solar"
  | "Hydro"
  | "Coal"
  | "Nuclear"
  | "Biomass";
type LandStatus = "Executed Lease" | "Lease Available" | "Owned";
type Zoning = "Commercial" | "Agricultural";
type WaterAvailability = "Well Water" | "City Water";
type Internet = "Fiber" | "Cable";
type SiteStatus = "Greenfield" | "Turnkey";
type Voltage = "34.5 kV" | "345 kV";

export interface Site {
  dateAdded: Date;
  name: string;
  salesCostPerMw?: number;
  uptime?: number | null;
  uptimeRange: boolean;
  uptimeLow?: number | null;
  uptimeHigh?: number | null;
  description: string[];
  ppaInPlace: boolean;
  siteStatus: SiteStatus;
  locationState: string;
  powerAvailableNowMw: number;
  powerAvailableTotalMw: number;
  interconnectionCost: number;
  lowVoltageKv: Voltage;
  transVoltageKv: Voltage;
  fuelSources: FuelSource[];
  landAvailableAcr: number;
  landStatus: LandStatus;
  zoning: Zoning;
  allInPowerCostMwh?: number | null;
  allInPowerCostLowMwh?: number | null;
  allInPowerCostHighMwh?: number | null;
  allInPowerCostDisplay?: string | null;
  allInPowerCostLowDisplay?: string | null;
  allInPowerCostHighDisplay?: string | null;
  waterAvailability: WaterAvailability;
  internet: Internet;
  leaseTerm: number;
  leaseDescription: string;
  leaseAmountMonth: number;
  imageSrc: string;
  id: string;
}

export default function BrowseSites({ sites }: { sites: Site[] }) {
  return (
    <Panel backgroundClass={"bg-slate-200"}>
      <div className={"flex flex-col justify-center items-center"}>
        <span className={"font-serif text-center text-2xl"}>
          Browse available sites
        </span>
        <SiteGrid sites={sites}></SiteGrid>
      </div>
    </Panel>
  );
}
