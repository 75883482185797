import React from "react";
import { CheckIcon } from "@heroicons/react/20/solid";

export default function SiteDescription({ description }) {
  return (
    <div className="text-base text-gray-500">
      <div className="grid grid-rows-1 gap-y-2">
        {description.map((desc, index) => (
          <span
            key={index}
            className="inline-flex items-center justify-start gap-x-1"
          >
            <CheckIcon className="h-4 w-4" />
            {desc}
          </span>
        ))}
      </div>
    </div>
  );
}
