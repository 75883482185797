import Badge from "./badge";
import React from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/20/solid";
import RateBadge from "./rate-badge";
import SiteDescription from "./site-description";

export default function SiteCard({ site }) {
  return (
    <div
      key={site.id}
      className="group relative flex flex-col rounded-lg border border-gray-200 bg-white hover:bg-slate-50"
    >
      <div className="flex flex-1 flex-col space-y-8 p-4">
        <div className={"flex flex-1 flex-row justify-between items-start"}>
          <h3 className="text-sm font-medium text-gray-900">
            <a href={`sites/${site.id}`}>
              <span aria-hidden="true" className="absolute inset-0" />
              {site.name} ({site.locationState}, US)
            </a>
          </h3>
          <RateBadge site={site} />
        </div>
        <SiteDescription description={site.description} />
        <div className="flex flex-1 flex-col justify-end">
          {/*<p className="text-sm italic text-gray-500">*/}
          {/*  {site.powerAvailableNowMw} MW Available (Scale to{" "}*/}
          {/*  {site.powerAvailableTotalMw} MW)*/}
          {/*</p>*/}
          <div className={"flex flex-1 flex-row justify-between items-end"}>
            <p className="text-base font-medium text-gray-900">
              {site.powerAvailableNowMw} MW Available
            </p>
            {site.siteStatus === "greenfield" ? (
              <Badge
                color={"green"}
                text={"Greenfield"}
                tooltipText={
                  "This site requires additional development before mining ready."
                }
              />
            ) : (
              <Badge
                color={"blue"}
                text={"Turnkey"}
                tooltipText={"This site is plug-in ready for bitcoin mining."}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
