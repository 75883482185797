import React from "react";
import RailsForm from "./rails-form";
import Layout from "../web-components/layout";

export default function ListSiteRequestForm() {
  return (
    <Layout>
      <div className="max-w-lg mx-auto mt-8 pb-8">
        <h2 className="text-2xl font-semibold mb-2">Get Your Site Listed</h2>
        <p className="text-sm text-gray-500 mb-6">
          Please provide your contact information, as well as details about the
          site you would like listed. A member of our team will reach out to
          connect shortly.
        </p>
        <RailsForm
          className="flex flex-col gap-4"
          action="/list_site_requests"
          method="post"
        >
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Name
            </label>
            <input
              name="list_site_request[name]"
              id="name"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
              placeholder="Your name"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Email
            </label>
            <input
              type="email"
              name="list_site_request[email]"
              id="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
              placeholder="you@example.com"
            />
          </div>
          <div>
            <label
              htmlFor="company"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Company (optional)
            </label>
            <input
              type="text"
              name="list_site_request[company]"
              id="company"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
              placeholder="Your company"
            />
          </div>
          <div>
            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Site Details
            </label>
            <textarea
              name="list_site_request[message]"
              id="message"
              rows={8}
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-main sm:text-sm sm:leading-6"
              placeholder="Please provide details about the site you would like listed."
            />
          </div>
          <button
            type="submit"
            className="mt-4 inline-flex w-full items-center justify-center rounded-md bg-main py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main"
          >
            Submit
          </button>
        </RailsForm>
      </div>
    </Layout>
  );
}
