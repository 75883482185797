import { ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import AnimatedElement from "./animated-element";

interface CtaBannerProps {
  text: string;
  ctaText: string;
  onClick?: () => void;
}

export default function CtaBanner({
  onClick,
  text = "Get in contact to learn more",
  ctaText = "Contact Us",
}: CtaBannerProps) {
  return (
    <div className={"bg-slate-900 py-20"}>
      <div className={"flex flex-col justify-center space-y-8"}>
        <div
          className={
            "text-2xl lg:text-4xl font-serif font-semibold text-center text-main-grey"
          }
        >
          <AnimatedElement
            animationClass={
              "animate-fade-in lg:animate-fade-in lg:animate-slide-in"
            }
          >
            {text}
          </AnimatedElement>
        </div>
        <div className="my-2 mx-auto border-2 border-main-grey text-main-grey rounded-md">
          <AnimatedElement
            animationClass={
              "animate-fade-in lg:animate-fade-in lg:animate-slide-in"
            }
          >
            <button
              onClick={onClick}
              type={"submit"}
              className="inline-flex items-center font-serif rounded-md bg-slate-900 px-6 py-3 text-base font-semibold text-white shadow-sm hover:bg-main whitespace-nowrap focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-main"
            >
              {ctaText}
              <ChevronRightIcon className={"w-6 h-6 ml-1 inline"} />
            </button>
          </AnimatedElement>
        </div>
      </div>
    </div>
  );
}
