import React from "react";
import Tooltip from "./tooltip";

export default function Badge({ color, text, tooltipText }) {
  let bgClass, textClass;

  switch (color) {
    case "green":
      bgClass = "bg-green-100";
      textClass = "text-green-600";
      break;
    case "blue":
      bgClass = "bg-blue-100";
      textClass = "text-blue-600";
      break;
    case "indigo":
      bgClass = "bg-indigo-100";
      textClass = "text-indigo-600";
      break;
    case "teal":
      bgClass = "bg-teal-100";
      textClass = "text-teal-600";
      break;
    case "cyan":
      bgClass = "bg-cyan-100";
      textClass = "text-cyan-600";
      break;
    case "purple":
      bgClass = "bg-purple-100";
      textClass = "text-purple-600";
      break;
    default:
      bgClass = "bg-gray-100";
      textClass = "text-gray-600";
  }

  return (
    // <Tooltip text={tooltipText}>
    <span
      className={`inline-flex items-center rounded-md ${bgClass} 
      px-2 py-1 text-sm font-medium ${textClass} max-h-[24px] whitespace-nowrap`}
    >
      {text}
    </span>
    // </Tooltip>
  );
}
