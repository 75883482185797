import React from "react";
import { Site } from "./browse-sites";

export interface RateBadgeProps {
  site: Site;
}

export default function RateBadge({ site }: RateBadgeProps) {
  return (
    <span
      className={
        "bg-slate-600 text-slate-50 text-sm font-semibold rounded-md px-2 py-1"
      }
    >
      {site.allInPowerCostMwh ? (
        <span className={"bg-inherit"}>
          ${site.allInPowerCostDisplay} / kWh
        </span>
      ) : (
        <span>
          ${site.allInPowerCostLowDisplay} - ${site.allInPowerCostHighDisplay} /
          kWh
        </span>
      )}
    </span>
  );
}
