import ReactOnRails from "react-on-rails";
import Home from "../app/components/home";
import SiteShow from "../app/components/site-show";
import ListSiteRequestForm from "../app/components/list-site-request-form";

// This is how react_on_rails can see the Home in the browser.
ReactOnRails.register({
  Home: Home,
  SiteShow: SiteShow,
  ListSiteRequestForm: ListSiteRequestForm,
});
