import React from "react";
import { Site } from "./browse-sites";
import Badge from "./badge";
import SiteCard from "./site-card";

export default function SiteGrid({ sites }: { sites: any[] }) {
  return (
    <div>
      <div className="mx-auto max-w-2xl py-8 lg:py-16 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-3 lg:gap-x-8">
          {sites.map((site) => (
            <SiteCard site={site} />
          ))}
        </div>
      </div>
    </div>
  );
}
